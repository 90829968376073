'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import ButtonCTA from 'components/ui/ButtonCTA';
import ButtonCircle from 'components/ui/ButtonCircle';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import Filters from './Filters';
import Tab from './Filters/Tab';
import Header from './Header';
import Hero from './Hero';
import Newsletter from './Newsletter';
import RecipesList from './RecipesList';

import styles from './Recipes.module.scss';

const Recipes = ({
    blocks,
    recipes,
    recipesConfig: _recipesConfig,
    template,
    filterSlug,
    isSearch,
    searchQuery,
}) => {
    const [activeFilters, setActiveFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [limit, setLimit] = useState(10);

    const featuredRecipe = template?.featuredRecipe;

    const recipesConfig = useMemo(() => {
        const filterAttributes = _recipesConfig || {};

        const creatorsOptions = template?.filtersCreators.map((item, index) => {
            return {
                id: index,
                slug: kebabCase(item.name),
                label: item.name,
            };
        });

        const creatorsAttributes = {
            creators: {
                order: 4,
                slug: 'creators',
                type: 'single',
                label: 'Chef/Author',
                options: [...creatorsOptions],
            },
        };

        return {
            ...filterAttributes,
            ...creatorsAttributes,
        };
    }, [_recipesConfig, template?.filtersCreators]);

    useEffect(() => {
        scrollTo({
            top: 0,
        });
    }, []);

    const creatorMatches = useMemo(() => {
        const creatorNames = recipesConfig.creators.options.map(creator => {
            return creator.label;
        });

        const matches = recipes.map(recipe => {
            const matches = recipe?.creatorsCollection.items.map(item => {
                return item?.fields?.name || item?.name;
            });
            return matches;
        });

        const indices = matches.map(creators => {
            if (creators.length === 0) return [-1];
            return creators.map(name => {
                return creatorNames.indexOf(name).toString();
            });
        });

        return indices;
    }, [recipesConfig.creators.options, recipes]);

    const recipesWithCreatorsAttributes = useMemo(() => {
        const output = recipes.map((recipe, index) => {
            const attributes = {
                ...recipe.attributes,
                creators: creatorMatches[index].filter(x => x !== '-1'),
            };
            return { ...recipe, attributes };
        });

        return output;
    }, [creatorMatches, recipes]);

    const handleFilterClick = useCallback(
        filter => {
            //on first filter click reset visible recipes limit to match filtered layout
            if (activeFilters.length === 0) {
                setLimit(6);
            }

            setActiveFilters(prevActiveFilters => {
                //if removing all filters reset visible recipes limit to match unfiltered layout
                if (
                    prevActiveFilters.length === 1 &&
                    filter.label === prevActiveFilters[0].label
                ) {
                    setLimit(10);
                }
                return prevActiveFilters.some(
                    item =>
                        item.id === filter.id &&
                        item.category === filter.category
                )
                    ? prevActiveFilters
                          .filter(
                              item =>
                                  item.id !== filter.id ||
                                  item.category !== filter.category
                          )
                          ?.sort()
                    : [...prevActiveFilters, { ...filter }].sort();
            });
        },
        [activeFilters.length]
    );

    const handleFilterClear = () => {
        setLimit(10);
        setActiveFilters([]);

        if (!isSearch) {
            history.pushState(history.state, '', '/recipes');
        }
    };

    const recipesFiltered = recipesWithCreatorsAttributes.filter(recipe => {
        if (activeFilters?.length === 0) {
            return true;
        }

        return activeFilters?.every(filter => {
            if (!recipe.attributes) {
                return false;
            }
            const option = recipesConfig[filter.category]?.options?.find(
                option => option.slug == filter.slug
            );

            if (Array.isArray(recipe.attributes[filter.category])) {
                return recipe.attributes[filter.category].includes(
                    `${option?.id}`
                );
            }
            return `${recipe.attributes[filter.category]}` === `${option?.id}`;
        });
    });

    const renderSearchCount = () =>
        recipes?.length ? (
            <Text
                className={styles.searchQuery}
                baseTheme="headingSmall"
            >{`${recipes?.length} results for “${searchQuery}”`}</Text>
        ) : (
            <div className={styles.noSearchResults}>
                <Text baseTheme="headingSmall">{`Sorry, we couldn’t find any results for “${searchQuery}”`}</Text>
                <ButtonCTA
                    text="View All Recipes "
                    style="filled-blue-ocean"
                    link={{ url: '/recipes' }}
                />
            </div>
        );

    return (
        <div
            className={cx(styles.root, {
                [styles.isSearch]: isSearch,
                [styles.isFiltered]: activeFilters.length,
            })}
        >
            {isSearch && (
                <Link href={'/recipes'} className={styles.navButton}>
                    <ButtonCircle
                        className={styles.navCaret}
                        theme="small-fill-orange"
                        iconType="chevronLeft"
                    />
                    <Text
                        as="span"
                        baseTheme="labelSmall"
                        themes={{ large: 'labelMedium' }}
                        className={styles.navLabel}
                    >
                        All Recipes
                    </Text>
                </Link>
            )}
            <Header
                className={styles.header}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
            />
            {isSearch ? renderSearchCount() : null}
            <CSSTransition
                in={showFilters}
                timeout={400}
                classNames={{
                    enter: styles['filters-enter'],
                    enterActive: styles['filters-enter-active'],
                    enterDone: styles['filters-enter-done'],
                    exit: styles['filters-exit'],
                    exitActive: styles['filters-exit-active'],
                    exitDone: styles['filters-exit-done'],
                }}
            >
                <Filters
                    className={styles.filters}
                    setActiveFilters={setActiveFilters}
                    activeFilters={activeFilters}
                    recipes={recipesFiltered}
                    recipesConfig={recipesConfig}
                    filters={recipesConfig}
                    filterSlug={filterSlug}
                    onFilterClick={handleFilterClick}
                    isSearch={isSearch}
                />
            </CSSTransition>
            {!isSearch && blocks && (
                <CSSTransition
                    in={activeFilters.length === 0}
                    timeout={{
                        enter: 500,
                        exit: 500,
                    }}
                    classNames={{
                        enter: styles['hero-enter'],
                        enterActive: styles['hero-enter-active'],
                        enterDone: styles['hero-enter-done'],
                        exit: styles['hero-exit'],
                        exitActive: styles['hero-exit-active'],
                        exitDone: styles['hero-exit-done'],
                    }}
                >
                    <div className={styles.hero}>{blocks}</div>
                </CSSTransition>
            )}
            <SwitchTransition>
                <CSSTransition
                    key={activeFilters.length > 0}
                    timeout={{
                        enter: 400,
                        exit: 400,
                    }}
                    classNames={{
                        enter: styles['activeFilters-enter'],
                        enterActive: styles['activeFilters-enter-active'],
                        enterDone: styles['activeFilters-enter-done'],
                        exit: styles['activeFilters-exit'],
                        exitActive: styles['activeFilters-exit-active'],
                        exitDone: styles['activeFilters-exit-done'],
                    }}
                >
                    <div className={styles.activeFilters}>
                        {activeFilters.map((item, index) => {
                            return (
                                <Tab
                                    key={`tab-${index}-${item.id}`}
                                    label={item.label}
                                    onClick={() => handleFilterClick(item)}
                                />
                            );
                        })}
                        <Button
                            className={cx(styles.clearAllButton, {
                                [styles.isVisible]: activeFilters.length > 0,
                            })}
                            theme="none"
                            onClick={handleFilterClear}
                        >
                            <Text baseTheme="labelSmall">Clear All</Text>
                        </Button>
                    </div>
                </CSSTransition>
            </SwitchTransition>
            {recipes?.length ? (
                <RecipesList
                    recipes={recipesFiltered}
                    activeFilters={activeFilters}
                    recipesConfig={recipesConfig}
                    limit={limit}
                    setLimit={setLimit}
                />
            ) : null}
            <Newsletter />
        </div>
    );
};

Recipes.propTypes = {
    filterSlug: PropTypes.object,
    recipes: PropTypes.array.isRequired,
    blocks: PropTypes.array.isRequired,
    isSearch: PropTypes.bool,
    recipesConfig: PropTypes.object,
    searchQuery: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            }).isRequired
        ),
        contentTypeId: PropTypes.string,
        featuredRecipe: PropTypes.object,
        filtersCreators: PropTypes.array,
    }),
};

export default Recipes;
